import "./App.css";

import {useEffect, useState} from "react";
import logoPlatzee from "./assets/Logo@150x.png";
import footer1 from "./assets/platzeereal.png";
import SearchByMail from "./Components/SearchByMail/SearchByMail";
import ArrowLeft from "./assets/arrow-left.svg";
import useWindowDimensions from "./hooks/useWindowDimensions";
import {BrowserRouter, Router, Route, Switch, Link} from "react-router-dom";
import Statistics from "./Components/Statistics/Statistics";

function App() {
  const {height, width} = useWindowDimensions();
  const [step, setStep] = useState(1);
  const [reset, setReset] = useState(false);
  const changeStep = (step) => {
    setStep(step);
  };

  const clickBack = () => {
    setStep(1);
    setReset(true);
  };
  useEffect(() => {
    if (reset) {
      setReset(false);
    }

    return () => {};
  }, [reset]);

  return (
    <div id="app-container" className="App App-background">
      <div className="App-Header">
        <div>
          {step == 2 && width < 768 && (
            <img
              src={ArrowLeft}
              alt="Platzee"
              className="img-fluid"
              onClick={clickBack}
            ></img>
          )}
        </div>
        <div className="App-HeaderImg">
          <a href="https://www.platzees.io" target="_blank">
            <img
              src={logoPlatzee}
              alt="Platzee"
              className="img-fluid img-clickable"
            ></img>
          </a>
        </div>
        <div></div>
      </div>
      <BrowserRouter >
        {" "}
        <Switch>
          <Route path="/stats">
            <Statistics setStep={changeStep}></Statistics>
          </Route>
          <Route path="/">
            <div className="App-body">
              {!reset && <SearchByMail setStep={changeStep}></SearchByMail>}
            </div>
          </Route> 
        </Switch>
      </BrowserRouter>

      <div className="footerMessage">
        ¿Necesitas ayuda en este proceso? Estamos para servirte.{" "}
        <a href="https://www.platzees.io/tickets">Haz clic aquí</a>.
      </div>
    </div>
  );
}

export default App;
