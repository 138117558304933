import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import loadingGif from "../../assets/loading_4.gif";

import { Button, Form } from "react-bootstrap";
import "./SearchByMail.css";
import callApi from "../../helpers/conectorApi";
import TransactionByMail from "../TransactionsByMail/TransactionByMail";
import useWindowDimensions from "./../../hooks/useWindowDimensions.js";
export default function SearchByMail(props) {
  const { height, width } = useWindowDimensions();
  const [dataOrder, setdataOrder] = useState();
  const [dataStep, setdataStep] = useState(1);
  const [dataPending, setdataPending] = useState();

  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const getEmailData = async (data) => {
    try {
      let response = await callApi(
        `/orders/email`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        false
      );
      let dat = response.data;
      let datTm = [];
      if (dat) {
        dat.map((item) => {
          if (item.Refunded == "NO") {
            datTm.push(item);
          }
        });
      }
      setdataOrder(datTm);
      setloading(false);
    } catch (error) {}
  };

  const onSubmit = (data) => {
    setdataOrder();
    setloading(true);
    getEmailData(data);
  };

  useEffect(() => {
    if (dataOrder) {
      const el = document.querySelector("#app-container");

      el.classList.remove("App-background");
      setdataStep(2);
      props.setStep(2);
    } else {
      const el = document.querySelector("#app-container");
      el.className += " " + "App-background";
    }

    return () => {};
  }, [dataOrder]);

  return (
    <Form noValidate className="form-mail" onSubmit={handleSubmit(onSubmit)}>
      {!dataOrder && (
        <>
          {" "}
          <div className="app-body-text1">¡Descubre tu número de Platzee!</div>
          <div className="app-body-text2">
            Ingresa tu correo electónico para revelar el número de Platzee que
            te ha tocado.
          </div>
        </>
      )}
      {(dataStep === 1 || (dataStep == 2 && width > 768)) && (
        <>
          <div className="app-email-rq">
            <Form.Control
              type="email"
              id="app-email-input"
              className="app-email-input"
              placeholder="Ingreso tu correo electónico"
              {...register("email", {
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />
            <Button
              variant=""
              className={`app-email-btn${!dataOrder ? "s1" : "s2"}`}
              type="submit"
            >
              Verificar
            </Button>
          </div>

          {errors.email && (
            <div className="ErrorMensaje">Ingrese un email válido.</div>
          )}
          {loading && (
            <div className="">
              <img src={loadingGif} alt="loading"></img>
            </div>
          )}
        </>
      )}
      {dataOrder && dataOrder !== undefined && dataOrder.length > 0 ? (
        <TransactionByMail data={dataOrder}></TransactionByMail>
      ) : dataStep === 2 ? (
        <div className="ErrorMensaje">
          No existe información con el correo electónico ingresado
        </div>
      ) : (
        <></>
      )}
    </Form>
  );
}
