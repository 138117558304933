import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import "./ConnectWallet.css";
export default function ConnectWallet(props) {
  const chainNeeded = "0x89"; // 0x13881 MUMBAI ; 0x4 RINKEBY
  const [validNetwork, setvalidNetwork] = useState(false);
  const [dataInfo, setDatainfo] = useState(props.data);
  const [currentAccount, setCurrentAccount] = useState("");
  const [buttonStyle, setButtonStyle] = useState("btnReveal");
  let provider;
  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      props.showError("Make sure you have metamask!");
      setButtonStyle("btnDark");
      return;
    }
    let chainId = await ethereum.request({ method: "eth_chainId" });
    if (chainId !== chainNeeded) {
      setvalidNetwork(false);
      props.showError("You are not connected to the Polygon Network!");
      setButtonStyle("btnDark");
      return;
    } else {
      setvalidNetwork(true);
    }
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account);

      // Setup listener! This is for the case where a user comes to our site
      // and ALREADY had their wallet connected + authorized.
      setupEventListener();
    }
    //else {
    //  swal("Oops!", "No authorized account found", "error");
    //}

    //setUriContract();
  };
  // Setup our listener.
  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;

      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
      } else {
        props.showError("Cannot connect to network!");
        setButtonStyle("btnDark");
      }
    } catch (error) {
      props.showError(error);
    }
  };

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      props.showError("");
      if (!ethereum) {
        props.showError("Get MetaMask!");
        setButtonStyle("btnDark");
        return;
      }

      if (!validNetwork) {
        props.showError("You are not connected to the correct network!");
        setButtonStyle("btnDark");
        return;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      props.walletSelected({ id: props.data.id, WalletAddress: accounts[0] });
      //console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
      // Setup listener! This is for the case where a user comes to our site
      // and connected their wallet for the first time.
      setupEventListener();
    } catch (error) {
      props.showError(error);
    }
  };

  const disconnectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        return;
      }

      setupEventListener();
    } catch (error) {
      props.showError(error);
    }
  };

  const renderNotConnectedContainer = () => (
    <button type="button" onClick={connectWallet} className={buttonStyle}>
      Conecta tu Wallet
    </button>
  );
  const renderConnectedContainer = () => (
    <button onClick={disconnectWallet} className="btnPlatzee">
      Disconnect Wallet
    </button>
  );

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);
  return currentAccount === "" ? (
    props.showConnect ? (
      renderNotConnectedContainer()
    ) : (
      ""
    )
  ) : props.showDisconnect ? (
    renderConnectedContainer()
  ) : (
    <>
      {" "}
      <p className="infoWalletCon">
        ¡Billetera conectada exitosamente! Abajo encontrarás los primeros y
        últimos números de la billetera a donde haremos llegar tus Platzees.
        Estaremos haciendo la entrega en la semana del 2 al 6 de mayo del año
        2022. Si necesitas cambiar de billetera o si tienes cualquier duda,
        puedes abrir un ticket presionando el botón en la parte de abajo de esta
        página. ¡Gracias por todo!
      </p>
      <p>{`${currentAccount.substring(0, 6)}...${currentAccount.substring(
        currentAccount.length - 4
      )}`}</p>
    </>
  );
}
