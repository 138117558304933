import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import React from "react";
import loadingGif from "../../assets/loading_5.gif";
import "./Statistics.css";
import callApi from "../../helpers/conectorApi";
import { Button, Form } from "react-bootstrap";
export default function Statistics(props) {
  const [dataOrders, setdataOrders] = useState();
  const [loadingTable, setloadingTable] = useState(true);
  const [view, setView] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const getData = async (data) => {
    try {
      let response = await callApi(
        `/orders/All`,
        {
          method: "GET",
        },
        false
      );
      let dat = response.data;

      setdataOrders(dat);
      setloadingTable(false);
      const el = document.querySelector("#app-container");

      el.classList.remove("App-background");
    } catch (error) {}
  };
  const onSubmit = (data) => {
    if (data.passPlatzee === "Pl@tzeeLover") {
      setView(true);
      setError();
    } else {
      setError("Password incorrecto, intentelo de nuevo.");
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="StatisticContainer">
      {!view ? (
        <Form
          noValidate
          className="form-pass"
          onSubmit={handleSubmit(onSubmit)}
        >
          {" "}
          <div className="app-rq">
            <Form.Control
              type="password"
              id="app-input"
              className="app-input"
              placeholder="Ingresa el password"
              {...register("passPlatzee", {
                required: true,
              })}
            />
            <Button variant="" className={`app-btn`} type="submit">
              Verificar
            </Button>
          </div>
          {error && <div className="ErrorMensaje">{error}</div>}
        </Form>
      ) : loadingTable ? (
        <div className="">
          <img src={loadingGif} alt="loading"></img>
        </div>
      ) : (
        <div className="Statistics">
          {dataOrders && (
            <>
              <div className="StatisticsItem">
                <p>Transacciones</p> <p>{dataOrders.transacciones}</p>
              </div>
              <div className="StatisticsItem">
                <p>NFTS Vendidos</p>
                <p>{dataOrders.vendidos}</p>
              </div>
              <div className="StatisticsItem">
                <p>NFTS Asignados</p>
                <p>{dataOrders.asignados}</p>
              </div>
              <div className="StatisticsItem">
                <p>NFTS Pendientes</p>
                <p>{dataOrders.pendienteAsignar}</p>
              </div>
              <div className="StatisticsItem">
                <p>Wallets Asignadas</p>
                <p>{dataOrders.walletAsignada}</p>
              </div>
              <div className="StatisticsItem">
                <p>Wallets Pendientes</p>
                <p>{dataOrders.walletPendientes}</p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
