import config from "../config";
import {Decrypt, Encrypt} from "./myCryptoJS";
const BASE_URL = config.urlApi;

const callApi = async (endpoint, options = {}, isCifrado = false) => {
  let resultApi = {};
  try {
    /* let token = Cookies.get("auth");
    if (token) {
      token = atob(token);
    } */
    options.headers = {
      /*  Authorization: `Bearer ${token}`, */
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    if (endpoint === "/auth/login") {
      delete options.headers.Authorization;
    }
    if (options.body !== undefined) {
      if (isCifrado === true) {
        options.body = JSON.stringify({requestJS: Encrypt(options.body)});
      }
    } else {
      if (isCifrado === true) {
        options.headers.axnjawzyywrv = 1;
      }
    }
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    if (response.status === 401) {
      resultApi.data = "El token enviado no es válido";
      return resultApi;
    } else {
      const result = await response.json();
      if (result) {
        let {error, status, body} = result;
        if (isCifrado === true) {
          body = Decrypt(body);
        }
        if (error === true) {
          resultApi.error = true;
          resultApi.data = body;
          return resultApi;
        } else {
          resultApi.error = false;
          resultApi.data = body;
          return resultApi;
        }
      } else {
        resultApi.error = true;
        resultApi.data =
          "Error con la comunicacion, comuniquese con el administrador.1";
        return resultApi;
      }
    }
  } catch (mensajeError) {
    resultApi.error = true;
    resultApi.data =
      "Error con la comunicacion, comuniquese con el administrador.";
    return resultApi;
  }
};
export default callApi;
