import {useEffect, useState} from "react";
import React from "react";
import {Table, Button} from "react-bootstrap";
import loadingGif from "../../assets/loading_5.gif";
import "./TransactionByMail.css";
import callApi from "../../helpers/conectorApi";
import useWindowDimensions from "./../../hooks/useWindowDimensions.js";
import Confetti from "react-confetti";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

export default function TransactionByMail(props) {
  const urlPlatzee =
    "https://rarible.com/token/polygon/0xfec50dae05902f4a1c303da8fb7477f7dea751d5:";
  const { height, width } = useWindowDimensions();

  const [dataOrders, setdataOrders] = useState(props.data);
  const [errors, setErrors] = useState();

  const [email, setEmail] = useState(props.data[0].Email);
  const [dataInfo, setdataInfo] = useState();
  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);

  const [randomNumbers, setrandomNumbers] = useState();
  const [codigoNumbers, setcodigoNumbers] = useState();
  const [interval, setinterval] = useState();
  const [intervalId, setIntervalId] = useState(0);
  const [confetti, setconfetti] = useState(false);
  const [pieces, setpieces] = useState(500);
  const [dataNumbers, setdataNumbers] = useState();

  useEffect(() => {
    if (pieces <= 0) {
      clearInterval(intervalId);
    }

    return () => {};
  }, [pieces]);

  const runConfetti = () => {
    setpieces(500);
    const newIntervalId = setInterval(() => {
      setpieces((prevCount) => prevCount - 150);
    }, 1000);
    setIntervalId(newIntervalId);
    setconfetti(true);
  };

  const starGame = (data) => {
    setrandomNumbers();
    setdataNumbers();
    setcodigoNumbers();
    putReg(data);
    setloading(true);
    setinterval(
      setInterval(() => {
        getRandomNumber(1, 5000);
      }, 100)
    );
  };

  const putWallet = async (data) => {
    try {
      let dataToSend = {
        id: data.id,
        WalletAddress: data.WalletAddress,
      };
      let response = await callApi(
        `/orders/data`,
        {
          method: "PUT",
          body: JSON.stringify(dataToSend),
        },
        false
      );
    } catch (error) {}
  };
  const getRandomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    setrandomNumbers(Math.floor(Math.random() * (max - min)));
  };

  const updateWallet = (dat) => {
    if (dataOrders) {
      dataOrders.forEach((element) => {
        putWallet({
          id: element.id,
          WalletAddress: dat.WalletAddress,
        });
      });
    }
  };

  const setWalletError = (data) => {
    if (data) {
      setErrors(data);
    }
  };
  const putReg = async (data) => {
    try {
      let dataToSend = {
        id: data.id
      };
      let response = await callApi(
        `/orders`,
        {
          method: "PUT",
          body: JSON.stringify(dataToSend)
        },
        false
      );
      setdataNumbers(response.data.data);
      setcodigoNumbers(response.data.codigo);
      setloading(false);
    } catch (error) {}
  };

  const getEmailData = async (data) => {
    try {
      let response = await callApi(
        `/orders/email`,
        {
          method: "POST",
          body: JSON.stringify(data)
        },
        false
      );
      let dat = response.data;
      let datTm = [];
      if (dat) {
        dat.map((item) => {
          if (item.Refunded == "NO") {
            datTm.push(item);
          }
        });
      }
      setdataOrders(datTm);
      setloadingTable(false);
    } catch (error) {}
  };
  useEffect(() => {
    if (dataOrders && dataOrders.length > 0) {
      var totalNft = 0;
      var totalNftLeft = 0;
      var pendiente = "";
      var tokensAsig = "";
      var tokensAsignados = [];
      dataOrders.map((item) => {
        totalNft = totalNft + item.NFTQuantity;
        totalNftLeft = totalNftLeft + item.NFTLeft;
        if (item.NFTLeft > 0) {
          pendiente = item.id;
        }
        if (item.NFTList) {
          item.NFTList.split(",").map((it) => {
            tokensAsignados.push(it);
          });
        }
      });
      if (tokensAsignados.length > 0) {
        tokensAsig = tokensAsignados.join(" , ");
      }
      let info = {
        name: dataOrders[0].Fullname,
        totalNft: totalNft,
        totalNftLeft: totalNftLeft,
        id: pendiente,
        wallet: dataOrders[0].WalletAddress,
        tokens: tokensAsignados,
      };
      setdataInfo(info);
    }
    return () => {};
  }, [dataOrders]);

  useEffect(() => {
    if (dataNumbers) {
      clearInterval(interval);
      setrandomNumbers(dataNumbers);
      setloadingTable(true);
      let data = {email: email};
      setdataOrders();
      getEmailData(data);
      runConfetti();
    }
  }, [dataNumbers]);

  return (
    <>
      {loadingTable && (
        <div className="flexw100">
          <img src={loadingGif} alt="loading"></img>
        </div>
      )}
      <div
        className={
          width > 768 ? "listPlatzeeContainer" : "listPlatzeeContainerCol"
        }
      >
        {!loadingTable && dataInfo && (
          <>
            <div className="itemPlatzee">
              <label>Nombre</label>
              <label>{dataInfo.name}</label>
            </div>
            <div className="itemPlatzee">
              <label>
                {dataInfo.totalNftLeft > 0 ? "Pendientes" : "Asignados"}
              </label>
              <label>
                {dataInfo.totalNftLeft > 0
                  ? dataInfo.totalNftLeft
                  : dataInfo.totalNft}
              </label>
            </div>
            {dataInfo.totalNftLeft > 0 && (
              <div className="itemPlatzeebtn">
                {!loading&&(
                <div>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      starGame(dataInfo);
                    }}
                  >
                    Asigna tu número
                  </button>
                </div>)
                }
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={
          width > 768 ? "listPlatzeeContainer" : "listPlatzeeContainerCol"
        }
      >
        {dataInfo && dataInfo.totalNftLeft === 0 && (
          <div className="listAssignedPlatzeesCont">
            <label>Mis Platzees</label>
            <div
              className={
                dataInfo.tokens.length <= 3
                  ? "listAssignedPlatzees1"
                  : "listAssignedPlatzees3"
              }
            >
              {dataInfo.tokens.map((item) => {
                return (
                  <p>
                    <a href={urlPlatzee + item} target="_blank">
                      {item}
                    </a>
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="itemReveal">
        {dataNumbers && codigoNumbers != -1 && (
          <div className="itemRevealCongrats">¡Felicidades!</div>
        )}
        {randomNumbers && (
          <div className={codigoNumbers !== -1 && "OrderNumberReveal"}>
            {" "}
            {codigoNumbers !== -1 && "# " + randomNumbers}
          </div>
        )}
        {confetti && (
          <Confetti
            width={width}
            height={height}
            numberOfPieces={pieces}
            tweenDuration={0.1}
          />
        )}
        {dataNumbers && codigoNumbers != -1 && (
          <>
            <div className="itemRevealInfo">
              Este es el tu número de Platzee. El siguiente paso es que conectes
              tu billetera de Metamask para que te podamos hacer la entrega de
              tu(s) Platzee(s) la semana del 2 al 6 de mayo del año 2022.
              {dataOrders && dataOrders[0].WalletAddress
                ? ""
                : "¡Haz clic aquí para conectar tu billetera!"}
            </div>

            {dataOrders && !dataOrders[0].WalletAddress ? (
              <div className="containerConnect">
                <ConnectWallet
                  walletSelected={updateWallet}
                  data={dataInfo}
                  showConnect={true}
                  showDisconnect={false}
                  showError={setWalletError}
                ></ConnectWallet>
              </div>
            ) : (
              dataOrders &&
              dataOrders[0].WalletAddress && (
                <div className="containerConnect ">
                  <p>
                    ¡Billetera conectada exitosamente! Abajo encontrarás los
                    primeros y últimos números de la billetera a donde haremos
                    llegar tus Platzees. Estaremos haciendo la entrega en la
                    semana del 2 al 6 de mayo del año 2022. Si necesitas cambiar
                    de billetera o si tienes cualquier duda, puedes abrir un
                    ticket presionando el botón en la parte de abajo de esta
                    página. ¡Gracias por todo!
                  </p>
                  <p>{`${dataOrders[0].WalletAddress.substring(
                    0,
                    6
                  )}...${dataOrders[0].WalletAddress.substring(
                    dataOrders[0].WalletAddress.length - 4
                  )}`}</p>
                </div>
              )
            )}

            {errors && (
              <div className="ErrorMensaje">
                Tu billetera no está conectada a Polygon todavía. ¡Sigue las
                instrucciones que detallamos abajo de este mensaje!
              </div>
            )}
            <div className="containerInfo">
              <div className="seccionInfo">
                <p className="seccionTitulo">
                  Instrucciones para conectar tu billetera digital de Polygon
                </p>
                <p className="seccionDescripcion">
                  No te preocupes, conectar tu billetera digital a nuestro sitio
                  es un proceso rápido y muy sencillo. Te invitamos a que veas
                  este video, donde te llevamos paso a paso.
                </p>
              </div>

              <div className="seccionVideo">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/pjS354w_GCw"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="seccionInfo">
                <p className="seccionTitulo">
                  ¿Cómo conectar tu billetera en tres sencillos pasos?{" "}
                </p>
                <p className="seccionSubtitulo">Paso #1</p>
                <p className="seccionDescripcion">
                  Descarga la extensión de Google Chrome para Metamask.
                  <a href="https://metamask.io/" target="_blank">
                    Haz clic para descargar
                  </a>
                </p>
                <p className="seccionSubtitulo">Paso #2</p>
                <p className="seccionDescripcion">
                  Ingresa a Chainlist.org y conéctate al "Polygon Mainnet".
                  <a href="https://chainlist.org/" target="_blank">
                    Haz clic para ir al sitio
                  </a>
                </p>
                <p className="seccionSubtitulo">Paso #3</p>
                <p className="seccionDescripcion">
                  Una vez ya hiciste los dos pasos de arriba, dale clic al botón
                  rosado que dice "Conecta tu wallet". ¡Es así de sencillo!
                </p>
                <p className="seccionDescripcionLight">
                  ¿Estás teniendo problemas conectando tu billetera? Quiero que
                  sepas que estamos para servirte. Te invito a que le des clic
                  aquí para abrir un ticket y nuestro equipo estará en contacto
                  contigo en menos de 24 horas, sin contar fines de semana.
                  ¡Mientras tanto, te invitamos que sigas probando y siguiendo
                  paso a paso el video que ves en esta pantalla, porque te
                  garantizamos que no es tan complicado como parece!
                </p>
              </div>
            </div>
          </>
        )}
        {codigoNumbers === -1 && (
          <div className="ErrorMensaje">{dataNumbers}</div>
        )}
        {dataOrders && dataOrders[0].WalletAddress ? (
          <div className="containerConnect ">
            <p>
              ¡Billetera conectada exitosamente! Abajo encontrarás los primeros
              y últimos números de la billetera a donde haremos llegar tus
              Platzees. Estaremos haciendo la entrega en la semana del 2 al 6 de
              mayo del año 2022. Si necesitas cambiar de billetera o si tienes
              cualquier duda, puedes abrir un ticket presionando el botón en la
              parte de abajo de esta página. ¡Gracias por todo!
            </p>
            <p>{`${dataOrders[0].WalletAddress.substring(
              0,
              6
            )}...${dataOrders[0].WalletAddress.substring(
              dataOrders[0].WalletAddress.length - 4
            )}`}</p>
          </div>
        ) : dataOrders && !dataOrders[0].WalletAddress ? (
          <>
            <div className="containerConnect">
              <ConnectWallet
                walletSelected={updateWallet}
                data={dataInfo}
                showConnect={true}
                showDisconnect={false}
                showError={setWalletError}
              ></ConnectWallet>
            </div>
            {errors && (
              <div className="ErrorMensaje">
                {errors}
                Tu billetera no está conectada a Polygon todavía. ¡Sigue las
                instrucciones que detallamos abajo de este mensaje!
              </div>
            )}

            <div className="containerInfo">
              <div className="seccionInfo">
                <p className="seccionTitulo">
                  Instrucciones para conectar tu billetera digital de Polygon
                </p>
                <p className="seccionDescripcion">
                  No te preocupes, conectar tu billetera digital a nuestro sitio
                  es un proceso rápido y muy sencillo. Te invitamos a que veas
                  este video, donde te llevamos paso a paso.
                </p>
              </div>

              <div className="seccionVideo">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/pjS354w_GCw"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="seccionInfo">
                <p className="seccionTitulo">
                  ¿Cómo conectar tu billetera en tres sencillos pasos?{" "}
                </p>
                <p className="seccionSubtitulo">Paso #1</p>
                <p className="seccionDescripcion">
                  Descarga la extensión de Google Chrome para Metamask.
                  <a href="https://metamask.io/" target="_blank">
                    Haz clic para descargar
                  </a>
                </p>
                <p className="seccionSubtitulo">Paso #2</p>
                <p className="seccionDescripcion">
                  Ingresa a Chainlist.org y conéctate al "Polygon Mainnet".
                  <a href="https://chainlist.org/" target="_blank">
                    Haz clic para ir al sitio
                  </a>
                </p>
                <p className="seccionSubtitulo">Paso #3</p>
                <p className="seccionDescripcion">
                  Una vez ya hiciste los dos pasos de arriba, dale clic al botón
                  rosado que dice "Conecta tu wallet". ¡Es así de sencillo!
                </p>
                <p className="seccionDescripcionLight">
                  ¿Estás teniendo problemas conectando tu billetera? Quiero que
                  sepas que estamos para servirte. Te invito a que le des clic
                  aquí para abrir un ticket y nuestro equipo estará en contacto
                  contigo en menos de 24 horas, sin contar fines de semana.
                  ¡Mientras tanto, te invitamos que sigas probando y siguiendo
                  paso a paso el video que ves en esta pantalla, porque te
                  garantizamos que no es tan complicado como parece!
                </p>
              </div>
            </div>
          </>
        ) : (
          dataOrders &&
          dataOrders[0].WalletAddress && (
            <div className="containerConnect ">
              <p>
                ¡Billetera conectada exitosamente! Abajo encontrarás los
                primeros y últimos números de la billetera a donde haremos
                llegar tus Platzees. Estaremos haciendo la entrega en la semana
                del 2 al 6 de mayo del año 2022. Si necesitas cambiar de
                billetera o si tienes cualquier duda, puedes abrir un ticket
                presionando el botón en la parte de abajo de esta página.
                ¡Gracias por todo!
              </p>
              <p>{`${dataOrders[0].WalletAddress.substring(
                0,
                6
              )}...${dataOrders[0].WalletAddress.substring(
                dataOrders[0].WalletAddress.length - 4
              )}`}</p>
            </div>
          )
        )}
      </div>
    </>
  );
}
